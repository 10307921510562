import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, colors, LabeledIconInput, Popup, Row, Toggle, ToggleLink, Typography } from '@commonsku/styles';
import { useDispatch } from 'react-redux';
import { useIdentity } from '../hooks';
import { closePopup } from '../actions/popup';
import updateOrder from '../actions/order';

interface OrderPaymentCreditCardFeePopupProps {
    order: any;
    client: any;
}

const OrderPaymentCreditCardFeePopup = ({ order, client }: OrderPaymentCreditCardFeePopupProps) => {
    const dispatch = useDispatch();
    const identity = useIdentity();
    const onPopupClose = () => dispatch(closePopup());
    const save = useCallback((field, value) => {
        dispatch(updateOrder(order.order_id, field, null, value));
    }, [dispatch, order.order_id]);

    const defaultValues = useMemo(() => ({
        payment_cc_fee_enabled: 0,
        payment_cc_fee_percentage: 3.0,
    }), []);

    const defaultErrors = useMemo(() => ({
        payment_cc_fee_percentage: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultErrors);
    useEffect(() => {
        let values = defaultValues;
        values = {
            payment_cc_fee_enabled: order.payment_cc_fee_enabled ?? 0,
            payment_cc_fee_percentage: order.payment_cc_fee_percentage ?? 3.0,
        };
        setForm(values);
    }, [defaultValues, order.payment_cc_fee_enabled, order.payment_cc_fee_percentage]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultErrors);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['payment_cc_fee_percentage']) {
            if (data[field] === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultErrors]);

    return <Popup zIndex={10004} overlayZIndex={10003} header={<span></span>} style={{ width: 'auto', height: 'auto' }}>
        <div style={{ float: 'right' }}><button className="close-button" aria-label="Close modal" type="button" onClick={e => { e.preventDefault(); onPopupClose(); }}>
            <span aria-hidden="true" style={{ color: colors.teal[60] }}>&times;</span>
        </button></div>
        <Typography.H5>Credit Card Fee</Typography.H5>
        <Row>
            <Col padded sm={12}>
                <Typography style={{ fontWeight: 'bold' }}>Who pays the credit card fee?</Typography>
                <Toggle size="medium">
                    <ToggleLink onClick={() => {
                        setFormFieldValue({
                            target: {
                                name: 'payment_cc_fee_enabled',
                                value: '0',
                            }
                        });
                        save('payment_cc_fee_enabled', 0);
                    }} selected={+form.payment_cc_fee_enabled === 0}>{identity.company_name}</ToggleLink>
                    <ToggleLink onClick={() => {
                        setFormFieldValue({
                            target: {
                                name: 'payment_cc_fee_enabled',
                                value: '1',
                            }
                        });
                        save('payment_cc_fee_enabled', 1);
                    }} selected={+form.payment_cc_fee_enabled === 1}>{client.company_name}</ToggleLink>
                </Toggle>
            </Col>
            {+form.payment_cc_fee_enabled === 0 ? null :
                <Col padded sm={12}>
                    A line item for the credit card fee will be automatically added to the invoice.
                </Col>}
            <Col padded sm={6}>
                <LabeledIconInput Icon={<div>%</div>}
                    label='Fee'
                    name='payment_cc_fee_percentage'
                    value={form['payment_cc_fee_percentage']}
                    onChange={setFormFieldValue} onBlur={(e) => {
                        if (!validateForm(form)) {
                            return;
                        }
                        save('payment_cc_fee_percentage', form['payment_cc_fee_percentage']);
                    }} />
                {formErrors['payment_cc_fee_percentage'] !== '' ? <span style={{ color: 'red' }}>{formErrors['payment_cc_fee_percentage']}</span> : ''}
            </Col>
            <Col padded sm={6}><span style={{ fontWeight: 'bold' }}>Amount</span><br />${(order.total_total * form['payment_cc_fee_percentage'] / 100).toFixed(2)}</Col>
        </Row>
    </Popup>;
};

export default OrderPaymentCreditCardFeePopup;